import React, { useEffect, useState } from 'react'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'
import Modal from '../Modal'
import Skeleton from '../Skeleton'
import ReportMedia from './ReportMedia'
import {
  useGetReporTQuery,
  useGetReportQuery,
  useLazyGetAdsQuery,
  usePutViewMutation,
} from '../../store/strapi/strapi.api'
import { getMonth } from '../../libs/utils/get-month'
import { determineAspectRatio } from '../../libs/utils/determine-aspect-ratio'
import { IData, IMedia } from '../../modules'
import MediaSlider from './MediaSlider'
import AdsCard from './AdsCard'
import clsx from 'clsx'
import { randomRange } from '../../libs/utils/random-range'

export interface IReport {
  id: string
}

const Report = ({ content }: { content: IReport }) => {
  const [activeIdx, setActiveIdx] = useState(-1)
  const [isActiveModal, setIsActiveModal] = useState(false)
  const [countViews, setCountViews] = useState(0)

  const { data, isSuccess, isLoading } = useGetReportQuery(content.id)
  const {
    data: reporT,
    isSuccess: isSuccessReporT,
    isFetching: isFetchingReport,
  } = useGetReporTQuery(content.id, {
    refetchOnFocus: true,
    refetchOnReconnect: true,
  })
  const [putView, { data: countData }] = usePutViewMutation()
  const [getAds, { data: adsData }] = useLazyGetAdsQuery()
  const [mediaLib, setMediaLib] = useState<IData<IMedia>[]>()

  useEffect(() => {
    if (isSuccess && data?.data?.length !== 0) {
      getAds('')
      setMediaLib([...data?.data[0]?.attributes?.media_set?.data])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  const handleReport = (countViews: number = 1) => {
    if (isSuccess && !isFetchingReport && isSuccessReporT) {
      putView({
        id: reporT.data[0].id,
        count_views: (parseInt(reporT?.data[0]?.attributes?.count_views?.toString()) || 0) + countViews,
      })
    }
  }

  useEffect(() => {
    handleReport()
  }, [isSuccess, isSuccessReporT, isFetchingReport])

  return (
    <>
      {isLoading ? (
        <>
          <div className="flex h-full w-full justify-center">
            <img src="/images/loading.svg" alt="loading" />
          </div>
        </>
      ) : (
        <>
          <section id="ReportInfo" className="mt-16 flex justify-center p-2.5 uppercase">
            <div className="container">
              {data?.data.length !== 0 ? (
                <>
                  <Link to={`/places/${data?.data[0]?.attributes?.place?.data?.id}`}>
                    <h3 className="mb-4 w-fit text-lg underline decoration-1 hover:cursor-pointer hover:decoration-0">
                      {data?.data[0]?.attributes.place?.data?.attributes?.caption}
                    </h3>
                  </Link>
                  <h1 className="mb-4 font-[AvenirLT] text-3xl font-bold md:mb-8 md:text-4xl lg:mb-16 lg:text-6xl">
                    {data?.data[0]?.attributes.caption}
                  </h1>
                  <div className="flex flex-wrap justify-between text-base text-white/40">
                    <span>
                      {data?.data[0]?.attributes.date.split('-')[2]}{' '}
                      {getMonth(new Date(data?.data[0]?.attributes.date!))}
                    </span>
                    <div className="flex max-w-fit grow flex-wrap gap-x-8">
                      {data?.data[0]?.attributes?.photographer?.data && (
                        <span className="order-4 flex gap-1 sm:!order-1">
                          PH:
                          <div className="flex flex-col gap-[0.25rem]">
                            {data?.data[0]?.attributes.photographer?.data.map((ph) => {
                              return (
                                <Link
                                  to={`/photographer/${ph.id}`}
                                  className="text-white underline underline-offset-1"
                                >
                                  {ph.attributes.name}
                                </Link>
                              )
                            })}
                          </div>
                        </span>
                      )}
                      <span className="order-2">
                        Views: {data?.data[0]?.attributes.count_views}
                      </span>
                      <span className="order-3">
                        Pictures: {data?.data[0]?.attributes.media_set.data.length}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <span className="uppercase">No Items Found</span>
              )}
            </div>
          </section>
          <div className="relative mb-8 mt-8 flex justify-center p-2.5 md:mt-16">
            <div className="container">
              <div className="grid grid-cols-2 gap-5 font-sans lg:grid-cols-3">
                {adsData && (
                  <LazyLoad
                    className={clsx(`order-${randomRange(5, 9)}`, 'row-span-2')}
                    offset={500}
                    placeholder={<Skeleton />}
                  >
                    <AdsCard
                      preview_img={
                        adsData?.data?.attributes?.preview_vertical_image?.data?.attributes?.url
                      }
                      link_to={adsData?.data?.attributes?.link_to}
                    />
                  </LazyLoad>
                )}
                {mediaLib
                  ?.sort(function (a, b) {
                    if (a.attributes.name < b.attributes.name) {
                      return -1
                    }
                    if (a.attributes.name > b.attributes.name) {
                      return 1
                    }
                    return 0
                  })
                  .map((media, index) => {
                    return (
                      <LazyLoad
                        key={media.attributes.url + index}
                        className={clsx(
                          determineAspectRatio(media.attributes.width, media.attributes.height)
                            ? 'row-span-2'
                            : '',
                          `order-${index}`
                        )}
                        offset={500}
                        placeholder={<Skeleton />}
                      >
                        <ReportMedia
                          type={media.attributes.mime.split('/')[0] as 'image' | 'video'}
                          width={media.attributes.width}
                          height={media.attributes.height}
                          src={media.attributes.formats.medium.url || media.attributes.url}
                          isPreview={isActiveModal}
                          onClick={() => {
                            setActiveIdx(index)
                            setIsActiveModal(true)
                          }}
                        />
                      </LazyLoad>
                    )
                  })}
              </div>
            </div>
          </div>
          <Modal
            isOpen={isActiveModal}
            setIsOpen={setIsActiveModal}
            onClose={() => {
              setActiveIdx(-1)
              handleReport(countViews)
              setCountViews(0)
            }}
          >
            <>
              <MediaSlider
                {...{
                  setActiveIdx,
                  activeIdx,
                  mediaLib,
                  onSlide: () => {
                    handleReport()
                  },
                }}
              />
            </>
          </Modal>
        </>
      )}
    </>
  )
}

export default Report
