import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Booknow from './Buttons/Booknow'
import ButtonMenu from './Buttons/ButtonMenu'

const links = [
  { name: 'Events', src: '/events' },
  { name: 'Photo/Video', src: '/reports' },
  { name: 'Places', src: '/places' },
  { name: 'About Us', src: '/about' },
]

const Header = () => {
  const [isActiveMenu, setIsActiveMenu] = useState(false)

  const WideMenu = () => (
    <div className="hidden w-full lg:flex">
      {links.map(({ name, src }) => (
        <Link
          key={name}
          to={src}
          className="flex items-end px-4 py-2 text-xl text-white opacity-75 hover:opacity-100"
        >
          {name}
        </Link>
      ))}

      <Booknow onClick={() => setIsActiveMenu(false)} />
    </div>
  )

  const NarrowMenu = () => {
    if (!isActiveMenu) return <></>

    return (
      <div
        className="fixed left-0 top-0 flex h-[100dvh] w-full flex-col items-center bg-black/70 p-2.5 pt-20 backdrop-blur-sm lg:hidden"
        onClick={() => {
          setIsActiveMenu(false)
        }}
      >
        {links.map(({ name, src }) => (
          <Link
            key={name}
            to={src}
            className="flex items-end px-4 py-2 text-xl text-white opacity-75 hover:opacity-100"
            onClick={() => {
              setIsActiveMenu(false)
            }}
          >
            {name}
          </Link>
        ))}

        <Booknow onClick={() => setIsActiveMenu(false)} />
      </div>
    )
  }

  return (
    <>
      <header
        className="sticky top-0 z-50 flex justify-center bg-transparent p-2.5"
        style={{
          backgroundImage:
            'linear-gradient(#000,rgba(0,0,0,.56) 48%,rgba(0,0,0,.23) 74%,rgba(0,0,0,.1) 85%,transparent)',
        }}
      >
        <div className="container flex">
          <Link
            to={'/'}
            onClick={() => setIsActiveMenu(false)}
            className="z-[1] flex w-[8rem] items-end px-4 py-2 pl-0 text-xl text-white opacity-100 transition-[all] hover:opacity-100"
          >
            <img src="/logo.png" alt="Logo" />
          </Link>

          <WideMenu />
          <NarrowMenu />

          <ButtonMenu onClick={() => setIsActiveMenu(!isActiveMenu)} />
        </div>
      </header>
    </>
  )
}

export default Header
