import React from 'react'
import { clsx } from 'clsx'

export interface ICaption {
  caption: string
  size?: 'xl' | 'lg'
  onClick?: () => void
}

const Caption = ({ caption, size = 'xl', onClick }: ICaption) => {
  return (
    <>
      <section
        id="Caption"
        className={clsx(
          size === 'xl' ? 'text-2xl md:text-3xl lg:text-5xl' : 'text-xl md:text-2xl lg:text-4xl',
          'mt-8 flex min-w-full justify-center p-2.5 md:mt-16'
        )}
      >
        <div className="container">
          <div className="flex justify-between gap-2">
            <span className="font-bold uppercase tracking-wider">{caption}</span>
            {onClick && (
              <button
                onClick={() => {
                  onClick && onClick()
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-8 w-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default Caption
