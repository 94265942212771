import React from 'react'

export interface IButtonMenu {
  onClick: () => void
}

const ButtonMenu = ({ onClick }: IButtonMenu) => {
  return (
    <>
      <button
        type="button"
        className="z-[1] ml-auto flex max-w-[3rem] items-center justify-center lg:hidden"
        onClick={() => onClick()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="h-7 w-7"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
        </svg>
      </button>
    </>
  )
}

export default ButtonMenu
