import React, { useState, useEffect } from 'react'
import LazyLoad from 'react-lazyload'
import { useLazyGetReportsPreviewsQuery } from '../../store/strapi/strapi.api'
import Caption from '../Caption'
import Hero from '../Hero'
import Skeleton from '../Skeleton'
import ReportCard from './ReportCard'
import ReportFilter from './ReportFilter'

export interface IReportList {
  date?: string
  caption?: string
  place?: string
  ph?: string
}

const ReportsList = ({ date, caption, place, ph }: IReportList) => {
  const [getReportsPreviews, { data, isFetching, isSuccess }] = useLazyGetReportsPreviewsQuery()
  const [isActiveFilter, setIsActiveFilter] = useState(false)

  useEffect(() => {
    getReportsPreviews(
      [
        `${date === 'NaN-NaN-NaN' || !date ? '' : `&filters[date][$eq][0]=${date}`}`,
        `${caption ? `&filters[caption][$containsi][1]=${caption}` : ''}`,
        `${place ? `&filters[place][caption][$eqi][2]=${place}` : ''}`,
        `${ph ? `&filters[photohrapher][name][$eqi][3]=${ph}` : ''}`,
      ].join('')
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, caption, place, ph])

  return (
    <>
      <Hero />
      <Caption
        caption="Reports"
        onClick={() => {
          setIsActiveFilter((e) => !e)
        }}
      />
      <div className="relative mb-8 mt-8 flex justify-center p-2.5 md:mt-16">
        <div className="container">
          <ReportFilter
            isActive={isActiveFilter}
            dateFilter={date}
            captionFilter={caption}
            placeFilter={place}
            phFilter={ph}
          />
          <div className="grid grid-cols-1 gap-5 font-sans sm:grid-cols-2 lg:grid-cols-3">
            {isFetching && (
              <>
                <div className="flex w-full justify-center sm:col-span-2 lg:col-span-3">
                  <img src="/images/loading.svg" alt="loading" />
                </div>
              </>
            )}
            {isSuccess &&
              (data?.data.length !== 0 ? (
                data?.data.map((report, index) => {
                  return (
                    <LazyLoad
                      key={report?.attributes.caption + index}
                      offset={200}
                      placeholder={<Skeleton />}
                    >
                      <ReportCard
                        content={{
                          id: `${report?.id}`,
                          place: report?.attributes.place?.data?.attributes?.caption,
                          name: report?.attributes.caption,
                          preview:
                            report?.attributes?.preview_image?.data?.attributes?.formats?.medium
                              ?.url || report?.attributes?.preview_image?.data?.attributes?.url,
                          date: report?.attributes.date,
                        }}
                      />
                    </LazyLoad>
                  )
                })
              ) : (
                <span className="uppercase">No Items Found</span>
              ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default ReportsList
