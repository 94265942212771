import React from 'react'
import BooknowCard from '../components/BooknowCard'

const Booknow = () => {
  return (
    <>
      <BooknowCard />
    </>
  )
}

export default Booknow
