import React from 'react'
import { IData, IMedia } from '../../modules'
import clsx from 'clsx'

export interface IMediaSlider {
  activeIdx: number
  setActiveIdx: CallableFunction
  mediaLib?: IData<IMedia>[]
  onSlide: () => void
}

const MediaSlider = ({ activeIdx, setActiveIdx, mediaLib, onSlide }: IMediaSlider) => {
  return (
    <>
      <div className="relative h-full w-full">
        <a
          href={mediaLib && mediaLib[activeIdx].attributes.url}
          download={mediaLib && mediaLib[activeIdx].attributes.name}
          target="__blank"
          className="absolute -top-12"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-8 w-8 text-cyan-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>
        </a>
        <div className="my-auto h-[calc(100vh_-_68px)] w-full">
          {mediaLib &&
            (mediaLib[activeIdx]?.attributes.mime.split('/')[0] === 'image' ? (
              <img
                src={mediaLib[activeIdx]?.attributes.url}
                alt="Report content"
                loading="lazy"
                className="mx-auto h-full w-full select-none object-contain"
              />
            ) : (
              <video controls={true} className="mx-auto h-full w-full select-none object-contain">
                <source src={mediaLib[activeIdx]?.attributes.url} type="video/mp4" />
              </video>
            ))}
        </div>
        <div className="absolute top-2/4 flex w-full justify-between">
          <button
            className={clsx(activeIdx === 0 && 'pointer-events-none opacity-60')}
            disabled={activeIdx === 0}
            onClick={() => {
              setActiveIdx(activeIdx - 1)
              onSlide()
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-12 w-12 text-cyan-500"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          </button>
          <button
            className={clsx(
              activeIdx === mediaLib?.length! - 1 && 'pointer-events-none opacity-60'
            )}
            disabled={activeIdx === mediaLib?.length! - 1}
            onClick={() => {
              setActiveIdx(activeIdx + 1)
              onSlide()
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-12 w-12 text-cyan-500"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </button>
        </div>
      </div>
    </>
  )
}

export default MediaSlider
