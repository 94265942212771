import React from 'react'
import queryString from 'query-string'
import { useLocation, useParams } from 'react-router-dom'
import Place from '../components/Place'
import PlacesList from '../components/Place/PlacesList'

const Places = () => {
  const { slug } = useParams<{ slug: string }>()
  const location = useLocation()
  const parseQuery = queryString.parse(location.search)

  if (slug !== undefined && typeof slug === 'string') {
    return (
      <>
        <Place content={{ id: slug }} />
      </>
    )
  }

  return <PlacesList {...parseQuery} />
}

export default Places
