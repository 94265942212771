import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import Photographer from '../components/Photographer'

const Ph = () => {
  const { slug } = useParams<{ slug: string }>()

  if (slug !== undefined && typeof slug === 'string') {
    return (
      <>
        <Photographer content={{ id: slug }} />
      </>
    )
  }

  return <Navigate to={'/'} />
}

export default Ph
