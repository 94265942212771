import React from 'react'
import { clsx } from 'clsx'
import { determineAspectRatio } from '../../libs/utils/determine-aspect-ratio'

export interface IReportMedia {
  type: 'image' | 'video'
  width: number
  height: number
  src: string
  isPreview: boolean
  onClick?: () => void
}

const ReportMedia = ({ type, src, width, height, isPreview, onClick }: IReportMedia) => {
  return (
    <>
      <div
        className={clsx(
          determineAspectRatio(width, height) ? 'aspect-[2/3] h-full' : 'aspect-[3/2]',
          'relative flex h-full w-full animate-[fadeInUp_1s_ease-in-out_forwards] flex-col justify-between overflow-hidden rounded-2xl',
          'hover:cursor-pointer [&>img:first-child]:hover:scale-110 [&>video]:hover:scale-110'
        )}
        onClick={() => onClick && onClick()}
      >
        {type === 'image' ? (
          <img
            src={src}
            className="absolute left-0 top-0 -z-[1] h-full w-full object-cover transition-all duration-300"
            alt="Ph"
            loading="lazy"
          />
        ) : (
          <>
            <video
              onMouseMove={(e) => !isPreview && e.currentTarget.play()}
              onMouseLeave={(e) => !isPreview && e.currentTarget.pause()}
              controls={isPreview}
              className={clsx(
                'absolute left-0 top-0 -z-[1] h-full w-full object-cover transition-all duration-300',
                'h-full object-cover transition-all duration-300 hover:scale-110'
              )}
            >
              <source src={src} type="video/mp4" />
            </video>
            <img
              src="/images/video.svg"
              alt="video-icon"
              className="absolute right-4 top-4 h-8 w-8"
            />
          </>
        )}
      </div>
    </>
  )
}

export default ReportMedia
