import React from 'react'
import { clsx } from 'clsx'

export interface IAds {
  link_to: string
  preview_img: string
}

const AdsCard = ({ link_to, preview_img }: IAds) => {
  return (
    <>
      <a
        href={link_to}
        target="__blank"
        rel="noreferrer"
        className={clsx(
          'aspect-[2/3] h-full',
          'relative flex h-full w-full animate-[fadeInUp_1s_ease-in-out_forwards] flex-col justify-between overflow-hidden rounded-2xl',
          'hover:cursor-pointer [&>img:first-child]:hover:scale-110'
        )}
      >
        <img
          src={preview_img}
          className="absolute left-0 top-0 -z-[1] h-full w-full object-cover transition-all duration-300"
          alt="Ads"
          loading="lazy"
        />
      </a>
    </>
  )
}

export default AdsCard
