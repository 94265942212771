import React from 'react'

const Skeleton = () => {
  return (
    <>
      <div className="relative flex aspect-[3/2] animate-[fadeInUp_1s_ease-in-out_forwards] flex-col justify-between overflow-hidden rounded-2xl bg-gray-400/80 p-4 before:content-[''] hover:cursor-pointer [&>.background]:hover:scale-105" />
    </>
  )
}

export default Skeleton
