import React, { useState } from 'react'
import { useGetTypeOfPlaceQuery } from '../../store/strapi/strapi.api'
import clsx from 'clsx'
import { createSearchParams, useNavigate } from 'react-router-dom'

export interface IPlaceFilter {
  isActive: boolean
  captionFilter?: string
  typeFilter?: string
}

const PlaceFilter = ({ isActive, captionFilter, typeFilter }: IPlaceFilter) => {
  const navigate = useNavigate()

  const [caption, setCaption] = useState(captionFilter || '')
  const [type, setType] = useState(typeFilter || '')

  const { data: placeTypes } = useGetTypeOfPlaceQuery('')

  const goToSearch = ({
    filterCaption,
    filterType,
  }: {
    filterCaption: string
    filterType: string
  }) => {
    navigate({
      pathname: '/places',
      search: `?${createSearchParams({
        ...(filterCaption !== '' && { caption: filterCaption }),
        ...(filterType !== '' && { type: filterType }),
      })}`,
    })
  }

  return (
    <>
      <div
        className={clsx(
          isActive ? 'mb-12 h-auto' : 'mb-0 h-0',
          'flex flex-col overflow-hidden text-lg transition-all duration-300 lg:grid lg:grid-cols-3'
        )}
      >
        <input
          type="text"
          id="caption"
          placeholder="Name"
          defaultValue={caption}
          className="z-10 rounded-none rounded-t-2xl border-2 border-b-0 border-white bg-black px-4 py-2 !outline-none placeholder:text-lg placeholder:text-white/60 lg:rounded-l-full lg:border-b-2 lg:border-r-0"
          onChange={(e) => setCaption(e.currentTarget.value)}
        />
        <select
          id="type"
          onChange={(e) => setType(e.currentTarget.value)}
          value={type}
          className={clsx(
            !type && 'text-white/60',
            'z-10 appearance-none border-2 border-b-0 border-white bg-black px-4 py-2 !outline-none lg:border-b-2 lg:border-r-0'
          )}
        >
          <option value="" className="text-white/80">
            Type
          </option>
          {placeTypes?.data.map((type) => {
            return (
              <option
                key={type.attributes.type_name}
                value={type.attributes.type_name}
                className="text-white/80"
              >
                {type.attributes.type_name}
              </option>
            )
          })}
        </select>
        <button
          className="flex w-full items-end justify-center rounded-b-2xl border-2 border-cyan-500 bg-transparent bg-gradient-to-r from-transparent to-cyan-500 px-4 py-2 text-lg transition-colors hover:bg-cyan-500 lg:rounded-r-full"
          onClick={() => {
            const filterCaption = caption || ''
            const filterType = type || ''
            goToSearch({ filterCaption, filterType })
          }}
        >
          Search
        </button>
      </div>
    </>
  )
}

export default PlaceFilter
