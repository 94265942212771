import React, { useEffect } from 'react'
import {
  useGetPhotographerQuery,
  useLazyGetReportsPreviewsQuery,
} from '../../store/strapi/strapi.api'
import ReactMarkdown from 'react-markdown'
import clsx from 'clsx'
import Share from '../Buttons/Share'
import Caption from '../Caption'
import LazyLoad from 'react-lazyload'
import Skeleton from '../Skeleton'
import ReportCard from '../Reports/ReportCard'
import Loadmore from '../Buttons/Loadmore'
import { createSearchParams } from 'react-router-dom'

export interface IPh {
  id: string
}

const Photographer = ({ content }: { content: IPh }) => {
  const { data, isLoading, isSuccess } = useGetPhotographerQuery(content.id)

  const [getReportsPreviews, { data: dataReports }] = useLazyGetReportsPreviewsQuery()

  useEffect(() => {
    if (isSuccess) {
      getReportsPreviews(`&filters[photographer][name][$eqi][0]=${data?.data[0]?.attributes?.name}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  return (
    <>
      {isLoading ? (
        <>
          <div className="flex h-full w-full justify-center">
            <img src="/images/loading.svg" alt="loading" />
          </div>
        </>
      ) : (
        <>
          <section id="Arcticle" className="mt-8 flex justify-center p-2.5 lg:mt-16">
            <div className="container">
              {data?.data.length !== 0 ? (
                <div className="grid gap-8 lg:grid-cols-12">
                  <div className="order-2 col-span-1 grid content-start gap-8 lg:order-1 lg:col-span-5">
                    <h1 className="text-4xl font-bold uppercase lg:text-6xl">
                      {data?.data[0]?.attributes?.name}
                    </h1>
                    <div className="flex grid-cols-2 flex-wrap justify-between gap-4 text-lg">
                      <div className="w-full">
                        <span className="uppercase tracking-wide text-white/40">Bio: </span>
                        <ReactMarkdown
                          className={clsx(
                            'tracking-wid relative whitespace-pre-line font-sans text-base'
                          )}
                        >
                          {data?.data[0]?.attributes?.bio!}
                        </ReactMarkdown>
                      </div>
                      <div className="w-full">
                        <span className="mt-8 uppercase text-white/40">Share: </span>
                        <Share />
                      </div>
                    </div>
                  </div>
                  <div className="order-1 col-span-1 w-full lg:order-2 lg:col-span-7">
                    <div className="relative aspect-[3/2] w-full overflow-hidden rounded-3xl">
                      <img
                        className="absolute inset-0 z-0 h-full w-full object-cover"
                        src={
                          data?.data[0]?.attributes?.preview_image?.data?.attributes?.formats
                            ?.medium?.url
                        }
                        alt="Banner"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <span className="uppercase">No Items Found</span>
              )}
            </div>
          </section>
        </>
      )}
      {isSuccess && dataReports?.data.length !== 0 && (
        <>
          <Caption caption="More photos from this photographer" size="lg" />
          <div className="relative mb-8 mt-8 flex justify-center p-2.5 md:mt-16">
            <div className="container">
              <div className="grid grid-cols-1 gap-5 font-sans sm:grid-cols-2 lg:grid-cols-3">
                {dataReports?.data?.slice(0, 6).map((report, index) => {
                  return (
                    <LazyLoad
                      key={report?.attributes.caption + index}
                      offset={200}
                      placeholder={<Skeleton />}
                    >
                      <ReportCard
                        content={{
                          id: `${report?.id}`,
                          place: report?.attributes.place?.data?.attributes?.caption,
                          name: report?.attributes.caption,
                          preview:
                            report?.attributes?.preview_image?.data?.attributes?.formats?.medium
                              ?.url || report?.attributes?.preview_image?.data?.attributes?.url,
                          date: report?.attributes.date,
                        }}
                      />
                    </LazyLoad>
                  )
                })}
                <Loadmore
                  pathname="/reports"
                  search={`?${createSearchParams({
                    ph: data?.data[0]?.attributes?.name!,
                  })}`}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Photographer
