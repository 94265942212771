import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Header from './components/Header'
import General from './pages/General'
import Template from './components/Template'
import Reports from './pages/Reports'
import Events from './pages/Events'
import Places from './pages/Places'
import AboutUs from './pages/AboutUs'
import Booknow from './pages/Booknow'
import Org from './pages/Org'
import Ph from './pages/Ph'

export interface IRoute {
  Component: any
  title: string
  path: string
  url: string
}

const routes: IRoute[] = [
  {
    Component: General,
    title: 'General',
    path: '/',
    url: '/',
  },
  {
    Component: Reports,
    title: 'Report',
    path: '/reports/:slug?',
    url: '/reports/',
  },
  {
    Component: Events,
    title: 'Event',
    path: '/events/:slug?',
    url: '/events/',
  },
  {
    Component: Places,
    title: 'Place',
    path: '/places/:slug?',
    url: '/places/',
  },
  {
    Component: AboutUs,
    title: 'About Us',
    path: '/about/',
    url: '/about/',
  },
  {
    Component: Booknow,
    title: 'Booknow',
    path: '/booknow/',
    url: '/booknow/',
  },
  {
    Component: Org,
    title: 'Organizer',
    path: '/organizer/:slug?',
    url: '/organizer/',
  },
  {
    Component: Ph,
    title: 'Photographer',
    path: '/photographer/:slug?',
    url: '/photographer/',
  },
]

const Routers = () => {
  return (
    <>
      <Header />
      <Routes>
        {routes.map(({ title, path, Component }) => {
          return (
            <Route
              key={title + path}
              path={path}
              element={
                <Template>
                  <Component />
                </Template>
              }
            />
          )
        })}
      </Routes>
    </>
  )
}

export default Routers
