import React, { useEffect, useState } from 'react'
import LazyLoad from 'react-lazyload'
import { EPreviewImageSize } from '../../modules'
import { useLazyGetEventsPreviewsQuery } from '../../store/strapi/strapi.api'
import Caption from '../Caption'
import Hero from '../Hero'
import Skeleton from '../Skeleton'
import EventCard from './EventCard'
import EventFilter from './EventFilter'

export interface IEventList {
  date?: string
  caption?: string
  type?: string
  place?: string
}

const EventsList = ({ date, caption, type, place }: IEventList) => {
  const [getEventsPreviews, { data, isFetching, isSuccess }] = useLazyGetEventsPreviewsQuery()
  const [isActiveFilter, setIsActiveFilter] = useState(false)

  useEffect(() => {
    getEventsPreviews(
      [
        `${date === 'NaN-NaN-NaN' || !date ? '' : `&filters[date_start][$eq][0]=${date}`}`,
        `${caption ? `&filters[caption][$containsi][1]=${caption}` : ''}`,
        `${type ? `&filters[type_of_event][type_name][$eqi][2]=${type}` : ''}`,
        `${place ? `&filters[place][caption][$eqi][3]=${place}` : ''}`,
      ].join('')
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, caption, type, place])

  return (
    <>
      <Hero />
      <Caption
        caption="Upcoming events"
        onClick={() => {
          setIsActiveFilter((e) => !e)
        }}
      />
      <div className="relative mb-8 mt-8 flex justify-center p-2.5 md:mt-10">
        <div className="container">
          <EventFilter
            isActive={isActiveFilter}
            dateFilter={date}
            captionFilter={caption}
            typeFilter={type}
            placeFilter={place}
          />
          <div className="grid grid-cols-1 gap-5 font-sans sm:grid-cols-2 lg:grid-cols-3">
            {isFetching && (
              <>
                <div className="flex w-full justify-center sm:col-span-2 lg:col-span-3">
                  <img src="/images/loading.svg" alt="loading" />
                </div>
              </>
            )}
            {isSuccess &&
              (data?.data.length !== 0 ? (
                data?.data.map((event, index) => {
                  return (
                    <LazyLoad
                      key={event.attributes.caption + index}
                      offset={200}
                      placeholder={<Skeleton />}
                      className={
                        event.attributes.preview_image_size === EPreviewImageSize.SQUARE
                          ? 'lg:col-span-2 lg:row-span-2'
                          : event.attributes.preview_image_size === EPreviewImageSize.VERTICAL
                          ? 'row-span-2 [&>a]:aspect-[2/3] [&>a]:h-full'
                          : ''
                      }
                    >
                      <EventCard
                        content={{
                          id: `${event.id}`,
                          type: event.attributes.type_of_event?.data?.attributes?.type_name,
                          name: event.attributes.caption,
                          preview:
                            event.attributes.preview_image.data.attributes.formats?.medium?.url ||
                            event.attributes.preview_image.data.attributes.url,
                          date: event.attributes.date_start,
                        }}
                      />
                    </LazyLoad>
                  )
                })
              ) : (
                <span className="uppercase">No Items Found</span>
              ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default EventsList
