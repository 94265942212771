import React, { useEffect } from 'react'
import { createSearchParams, Link } from 'react-router-dom'
import { clsx } from 'clsx'
import Share from '../Buttons/Share'
import {
  useGetEventQuery,
  useLazyGetEventsPreviewsQuery,
  useLazyGetReportsPreviewsQuery,
} from '../../store/strapi/strapi.api'
import { getMonth } from '../../libs/utils/get-month'
import ReactMarkdown from 'react-markdown'
import Caption from '../Caption'
import Skeleton from '../Skeleton'
import LazyLoad from 'react-lazyload'
import EventCard from './EventCard'
import ReportCard from '../Reports/ReportCard'
import Loadmore from '../Buttons/Loadmore'

export interface IEvent {
  id: string
}

const Event = ({ content }: { content: IEvent }) => {
  const { data, isLoading, isSuccess } = useGetEventQuery(content.id)
  const [getEventsPreviews, { data: dataEvents }] = useLazyGetEventsPreviewsQuery()
  const [getReportsPreviews, { data: dataReports }] = useLazyGetReportsPreviewsQuery()

  useEffect(() => {
    if (isSuccess && data?.data?.length !== 0) {
      getReportsPreviews(
        `&filters[place][caption][$eqi][0]=${data?.data[0]?.attributes?.place?.data?.attributes?.caption}`
      )
      getEventsPreviews('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  return (
    <>
      {isLoading ? (
        <>
          <div className="flex h-full w-full justify-center">
            <img src="/images/loading.svg" alt="loading" />
          </div>
        </>
      ) : (
        <>
          <section id="Arcticle" className="mt-8 flex justify-center p-2.5 lg:mt-16">
            <div className="container">
              {data?.data.length !== 0 ? (
                <>
                  <div className="grid gap-8 lg:grid-cols-12">
                    <div className="order-2 col-span-1 grid content-start gap-8 lg:order-1 lg:col-span-5">
                      <h1 className="font-[AvenirLT] text-4xl font-bold uppercase lg:text-5xl xl:text-6xl">
                        {data?.data[0]?.attributes.caption}
                      </h1>
                      <div className="flex grid-cols-2 flex-wrap justify-between gap-4 text-lg">
                        <div>
                          <span className="uppercase text-white/40">Date: </span>
                          <span className="font-sans text-white">
                            {data?.data[0]?.attributes.date_start.split('-')[2]}{' '}
                            {getMonth(new Date(data?.data[0]?.attributes.date_start!))}
                          </span>
                        </div>
                        {data?.data[0]?.attributes.organizer?.data && (
                          <div>
                            <span className="uppercase text-white/40">Org: </span>
                            <Link
                              to={`/organizer/${data?.data[0]?.attributes.organizer?.data?.id}`}
                              className="uppercase text-white underline"
                            >
                              {data?.data[0]?.attributes.organizer?.data?.attributes?.name}
                            </Link>
                          </div>
                        )}
                        <div className="w-full">
                          <span className="uppercase text-white/40">Description: </span>
                          <ReactMarkdown
                            className={clsx(
                              'tracking-wid relative whitespace-pre-line font-sans text-base'
                            )}
                          >
                            {data?.data[0]?.attributes?.description!}
                          </ReactMarkdown>
                        </div>
                        {data?.data[0]?.attributes.tickets_link && (
                          <div className="w-full">
                            <span className="mt-8 uppercase text-white/40">Tickets: </span>
                            <a
                              href={data?.data[0]?.attributes.tickets_link}
                              rel="noreferrer"
                              target={'_blank'}
                              className="mx-auto mt-2 flex w-full max-w-[240px] items-end justify-center rounded-full border-2 border-cleo_dark_pink bg-transparent bg-gradient-to-r from-transparent to-cleo_dark_pink px-4 py-2 text-xl text-white transition-[all] hover:scale-95 hover:bg-cleo_dark_pink lg:mx-[unset]"
                            >
                              BUY TICKET
                            </a>
                          </div>
                        )}
                        <div className="w-full">
                          <span className="mt-8 uppercase text-white/40">Share: </span>
                          <Share />
                        </div>
                      </div>
                    </div>
                    <div className="order-1 col-span-1 w-full lg:order-2 lg:col-span-7">
                      <div className="relative aspect-[16/9] w-full overflow-hidden rounded-3xl">
                        <img
                          className="absolute inset-0 z-0 h-full w-full object-cover"
                          src={data?.data[0]?.attributes.preview_image?.data.attributes.url}
                          alt="Banner"
                        />
                        <div className="absolute inset-0 p-4 lg:p-12">
                          <span className="w-fit rounded-full bg-black/60 px-2 py-1 text-base uppercase backdrop-blur-[5px]">
                            {data?.data[0]?.attributes.type_of_event?.data.attributes.type_name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 flex w-full flex-col gap-3 text-lg lg:mt-16">
                    <div>
                      <span className="uppercase text-white/40">Location: </span>
                      <Link
                        to={`/places/${data?.data[0]?.attributes.place.data.id}`}
                        className="uppercase text-white underline"
                      >
                        {data?.data[0]?.attributes.place.data.attributes.caption}
                      </Link>
                    </div>
                    <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                      <div className="aspect-[3/2] overflow-hidden rounded-2xl">
                        <img
                          src={
                            data?.data[0]?.attributes.place.data.attributes.preview_image?.data
                              ?.attributes?.formats?.medium?.url ||
                            data?.data[0]?.attributes?.place?.data?.attributes?.preview_image?.data
                              ?.attributes?.url
                          }
                          className="h-full w-full object-cover"
                          alt="Location preview"
                        />
                      </div>
                      <div
                        className="aspect-[3/2] overflow-hidden rounded-2xl [&>iframe]:h-full [&>iframe]:w-full"
                        dangerouslySetInnerHTML={{
                          __html: data?.data[0]?.attributes.place.data.attributes.maps_frame!,
                        }}
                      />
                      <div className="flex flex-col gap-4 overflow-hidden rounded-2xl text-base">
                        <span className="text-lg uppercase text-white/40">Contacts: </span>
                        <div className="flex flex-col gap-2">
                          {data?.data[0].attributes.place.data.attributes.phone && (
                            <div className="flex items-start gap-4 uppercase">
                              <img src="/images/call.png" alt="icon" className="h-6 w-6" />
                              <span>{data?.data[0].attributes.place.data.attributes.phone}</span>
                            </div>
                          )}
                          {data?.data[0].attributes.place.data.attributes.web_site && (
                            <div className="flex items-start gap-4 uppercase underline underline-offset-1">
                              <img src="/images/website.png" alt="icon" className="h-6 w-6" />
                              <a
                                href={`${data?.data[0].attributes.place.data.attributes.web_site}`}
                                target={'__blank'}
                                rel={'noreferrer'}
                              >
                                {data?.data[0]?.attributes.place.data.attributes.caption}
                              </a>
                            </div>
                          )}
                          {data?.data[0].attributes.place.data.attributes.email && (
                            <div className="flex items-start gap-4 uppercase underline underline-offset-1">
                              <img src="/images/mail.png" alt="icon" className="h-6 w-6" />
                              <a
                                href={`mailto:${data?.data[0].attributes.place.data.attributes.email}`}
                              >
                                {data?.data[0].attributes.place.data.attributes.email}
                              </a>
                            </div>
                          )}
                          {data?.data[0].attributes.place.data.attributes.address && (
                            <div className="flex items-start gap-4 uppercase">
                              <img src="/images/address.png" alt="icon" className="h-6 w-6" />
                              <span>{data?.data[0].attributes.place.data.attributes.address}</span>
                            </div>
                          )}
                          {(data?.data[0].attributes.place.data.attributes.instagram_name ||
                            data?.data[0].attributes.place.data.attributes.facebook_name) && (
                            <div className="mt-4 flex flex-col gap-2">
                              {data?.data[0].attributes.place.data.attributes.instagram_name && (
                                <div className="flex items-start gap-4 uppercase underline underline-offset-1">
                                  <img src="/images/insta.png" alt="icon" className="h-6 w-6" />
                                  <a
                                    href={`https://www.instagram.com/${data?.data[0].attributes.place.data.attributes.instagram_name}`}
                                    target={'__blank'}
                                    rel={'noreferrer'}
                                  >
                                    @{data?.data[0].attributes.place.data.attributes.instagram_name}
                                  </a>
                                </div>
                              )}
                              {data?.data[0].attributes.place.data.attributes.facebook_name && (
                                <div className="flex items-start gap-4 uppercase underline underline-offset-1">
                                  <img src="/images/fb.png" alt="icon" className="h-6 w-6" />
                                  <a
                                    href={`https://www.facebook.com/${data?.data[0].attributes.place.data.attributes.facebook_name}`}
                                    target={'__blank'}
                                    rel={'noreferrer'}
                                  >
                                    /{data?.data[0].attributes.place.data.attributes.facebook_name}
                                  </a>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <span className="uppercase">No Items Found</span>
              )}
            </div>
          </section>
        </>
      )}
      {isSuccess && dataReports && dataReports?.data?.length !== 0 && (
        <>
          <Caption caption="More photos from this place" size="lg" />
          <div className="relative mt-4 flex justify-center p-2.5 md:mt-6 lg:mt-12">
            <div className="container">
              <div className="grid grid-cols-1 gap-5 font-sans sm:grid-cols-2 lg:grid-cols-3">
                {dataReports?.data?.slice(0, 2).map((report, index) => {
                  return (
                    <LazyLoad
                      key={report?.attributes.caption + index}
                      offset={200}
                      placeholder={<Skeleton />}
                    >
                      <ReportCard
                        content={{
                          id: `${report?.id}`,
                          place: report?.attributes.place?.data?.attributes?.caption,
                          name: report?.attributes.caption,
                          preview:
                            report?.attributes?.preview_image?.data?.attributes?.formats?.medium
                              ?.url || report?.attributes?.preview_image?.data?.attributes?.url,
                          date: report?.attributes.date,
                        }}
                      />
                    </LazyLoad>
                  )
                })}
                <Loadmore
                  pathname="/reports"
                  search={`?${createSearchParams({
                    place: data?.data[0].attributes.place.data.attributes.caption!,
                  })}`}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {isSuccess && dataEvents && dataEvents?.data?.length !== 0 && (
        <div className="flex flex-col justify-center">
          <Caption caption="You may also Like" size="lg" />
          <div className="relative mb-8 mt-4 flex justify-center p-2.5 md:mt-6 lg:mt-12">
            <div className="container">
              <div className="grid grid-cols-1 gap-5 font-sans sm:grid-cols-2 lg:grid-cols-3">
                {dataEvents?.data
                  ?.filter((e) => e.id !== data?.data[0].id)
                  .slice(0, 3)
                  .map((event, index) => {
                    return (
                      <LazyLoad
                        key={event.attributes.caption + index}
                        offset={0}
                        placeholder={<Skeleton />}
                      >
                        <EventCard
                          content={{
                            id: `${event.id}`,
                            type: event.attributes.type_of_event?.data?.attributes?.type_name,
                            name: event.attributes.caption,
                            preview:
                              event.attributes.preview_image.data.attributes.formats?.medium?.url ||
                              event.attributes.preview_image.data.attributes.url,
                            date: event.attributes.date_start,
                          }}
                        />
                      </LazyLoad>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Event
