import React from 'react'
import { Link } from 'react-router-dom'

export interface IBooknow {
  onClick: () => void
}

const Booknow = ({ onClick }: IBooknow) => {
  return (
    <>
      <Link
        to="/booknow"
        onClick={() => onClick()}
        className="mt-10 flex w-full max-w-[240px] items-end justify-center rounded-full border-2 border-cyan-500 bg-transparent bg-gradient-to-r from-transparent to-cyan-500 px-4 py-2 text-xl text-white opacity-100 transition-[all] hover:scale-95 hover:bg-cyan-500 hover:opacity-100 lg:ml-auto lg:mt-auto"
      >
        Book Now
      </Link>
    </>
  )
}

export default Booknow
