import React, { useState } from 'react'
import clsx from 'clsx'
import { useGetPhotographersQuery, useGetPlacesPreviewsQuery } from '../../store/strapi/strapi.api'
import { formatDate } from '../../libs/utils/format-date'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

export interface IReportFilter {
  isActive: boolean
  dateFilter?: string
  captionFilter?: string
  placeFilter?: string
  phFilter?: string
}

const ReportFilter = ({
  isActive,
  dateFilter,
  captionFilter,
  placeFilter,
  phFilter,
}: IReportFilter) => {
  const navigate = useNavigate()

  const [date, setDate] = useState(dateFilter || '')
  const [caption, setCaption] = useState(captionFilter || '')
  const [place, setPlace] = useState(placeFilter || '')
  const [ph, setPh] = useState(phFilter || '')

  const { data: phs } = useGetPhotographersQuery('')
  const { data: places } = useGetPlacesPreviewsQuery('')

  const goToSearch = ({
    filterDate,
    filterCaption,
    filterPlace,
    filterPh,
  }: {
    filterDate: string
    filterCaption: string
    filterPlace: string
    filterPh: string
  }) => {
    navigate({
      pathname: '/reports',
      search: `?${createSearchParams({
        ...(filterDate !== '' && { date: filterDate }),
        ...(filterCaption !== '' && { caption: filterCaption }),
        ...(filterPlace !== '' && { place: filterPlace }),
        ...(filterPh !== '' && { ph: filterPh }),
      })}`,
    })
  }

  return (
    <>
      <div
        className={clsx(
          isActive ? 'mb-12 h-auto' : 'mb-0 h-0',
          'flex flex-col overflow-hidden text-lg transition-all duration-300 lg:grid lg:grid-cols-5'
        )}
      >
        <input
          type="date"
          id="date"
          placeholder="Date"
          defaultValue={date}
          className={clsx(
            'relative z-10 w-full rounded-b-none rounded-t-2xl border-2 border-b-0 border-white bg-black px-4 py-2 text-white !outline-none placeholder:text-white/60 lg:rounded-l-full lg:border-b-2 lg:border-r-0',
            isMobile &&
              (date === '' || date === 'NaN-NaN-NaN') &&
              "before:absolute before:z-20 before:flex before:h-full before:w-full before:items-center before:pl-2 before:text-white/60 before:content-['Date']"
          )}
          onChange={(e) => setDate(formatDate(e.currentTarget.valueAsDate?.toString() || ''))}
          onSelect={(e) => setDate(formatDate(e.currentTarget.valueAsDate?.toString() || ''))}
        />
        <input
          type="text"
          id="caption"
          placeholder="Name"
          defaultValue={caption}
          className="z-10 rounded-none border-2 border-b-0 border-white bg-black px-4 py-2 !outline-none placeholder:text-lg placeholder:text-white/60 lg:border-b-2 lg:border-r-0"
          onChange={(e) => setCaption(e.currentTarget.value)}
        />
        <select
          id="place"
          onChange={(e) => setPlace(e.currentTarget.value)}
          value={place}
          className={clsx(
            !place && 'text-white/60',
            'z-10 appearance-none border-2 border-b-0 border-white bg-black px-4 py-2 !outline-none lg:border-b-2 lg:border-r-0'
          )}
        >
          <option value="" className="text-white/80">
            Place
          </option>
          {places?.data.map((type) => {
            return (
              <option
                key={type.attributes.caption}
                value={type.attributes.caption}
                className="text-white/80"
              >
                {type.attributes.caption}
              </option>
            )
          })}
        </select>
        <select
          id="Ph"
          onChange={(e) => setPh(e.currentTarget.value)}
          value={ph}
          className={clsx(
            !ph && 'text-white/60',
            'z-10 appearance-none border-2 border-b-0 border-white bg-black px-4 py-2 !outline-none lg:border-b-2 lg:border-r-0'
          )}
        >
          <option value="" className="text-white/80">
            Photographer
          </option>
          {phs?.data.map((type) => {
            return (
              <option
                key={type.attributes.name}
                value={type.attributes.name}
                className="text-white/80"
              >
                {type.attributes.name}
              </option>
            )
          })}
        </select>
        <button
          className="flex w-full items-end justify-center rounded-b-2xl border-2 border-cyan-500 bg-transparent bg-gradient-to-r from-transparent to-cyan-500 px-4 py-2 text-lg transition-colors hover:bg-cyan-500 lg:rounded-r-full"
          onClick={() => {
            const filterDate = date === 'NaN-NaN-NaN' || date === '' ? '' : `${date}`
            const filterCaption = caption || ''
            const filterPlace = place || ''
            const filterPh = ph || ''
            goToSearch({ filterDate, filterCaption, filterPlace, filterPh  })
          }}
        >
          Search
        </button>
      </div>
    </>
  )
}

export default ReportFilter
