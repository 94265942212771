import React from 'react'
import { Link } from 'react-router-dom'
import { clsx } from 'clsx'

export interface IPlaceCard {
  id: string
  type: string
  name: string
  price: string
  preview: string
}

const PlaceCard = ({ content }: { content: IPlaceCard }) => {
  return (
    <>
      <Link
        to={`/places/${content.id}`}
        className={clsx(
          'relative flex aspect-[3/2] animate-[fadeInUp_1s_ease-in-out_forwards] flex-col justify-between overflow-hidden rounded-2xl p-4 hover:cursor-pointer [&>img]:hover:scale-105',
          "before:absolute before:bottom-0 before:left-0 before:right-0 before:h-[200px] before:max-h-full before:bg-gradient-to-t before:from-black before:to-transparent before:content-['']"
        )}
      >
        <img
          src={content.preview}
          className="absolute left-0 top-0 -z-[1] h-full w-full object-cover transition-all duration-300"
          alt="Event card"
        />
        <span className="w-fit rounded-full bg-black/30 px-2 py-1 text-base uppercase backdrop-blur-[5px]">
          {content.type}
        </span>
        <div className="relative z-[1] flex flex-col px-2 py-1">
          <span className="overflow-hidden text-ellipsis whitespace-nowrap text-3xl font-medium uppercase">
            {content.name}
          </span>
          <span className="text-lg text-white/40">
            {content.price ? content.price : <span className="text-transparent">0</span>}
          </span>
        </div>
      </Link>
    </>
  )
}

export default PlaceCard
