import React from 'react'
import LazyLoad from 'react-lazyload'
import { useNavigate } from 'react-router-dom'

export interface ILoadmore {
  pathname: string
  search: string
}

const Loadmore = ({ pathname, search }: ILoadmore) => {
  const navigate = useNavigate()

  const goToSearch = () => {
    navigate({
      pathname,
      search,
    })
  }

  return (
    <>
      <LazyLoad offset={0}>
        <button
          onClick={() => {goToSearch()}}
          className="relative flex aspect-[3/2] w-full animate-[fadeInUp_1s_ease-in-out_forwards] flex-col justify-between overflow-hidden rounded-2xl p-4 before:content-[''] hover:cursor-pointer [&>div>span]:hover:scale-105"
        >
          <div className="absolute left-0 top-0 -z-[1] h-full w-full object-cover transition-all duration-300">
            <div className="absolute h-full w-full -translate-x-[60%] -translate-y-1/4 animate-[ping_2s_linear_infinite] rounded-full bg-cleo_dark_cyan" />
            <div className="absolute bottom-0 right-0 h-full w-full translate-x-[60%] translate-y-1/4 animate-[ping_2s_linear_infinite] rounded-full bg-cleo_dark_pink" />
            <div className="absolute h-full w-full backdrop-blur-3xl" />
          </div>
          <div className="relative z-[1] mb-[1.7rem] flex flex-col px-2 py-1">
            <span className="text-3xl font-bold uppercase transition-all duration-300">
              Load more ...
            </span>
          </div>
        </button>
      </LazyLoad>
    </>
  )
}

export default Loadmore
