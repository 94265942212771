import React, { useEffect, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

export interface ITemplate {
  children?: ReactNode
}

const Template = ({ children }: ITemplate) => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [location])

  return <>{children}</>
}

export default Template
