import React from 'react'
import { useResize } from '../libs/hooks/use-resize'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectCoverflow, Pagination } from 'swiper'
import { Link } from 'react-router-dom'
import 'swiper/css'
import 'swiper/css/pagination'
import { useGetBannersQuery } from '../store/strapi/strapi.api'

const Hero = () => {
  const windowSize = useResize()
  const { data, isLoading } = useGetBannersQuery('')

  return (
    <>
      <section id="Hero" className="mt-8 flex justify-center p-2.5 md:mt-16">
        <div className="container">
          <div className="flex">
            {isLoading && (
              <>
                <div className="flex w-full justify-center">
                  <img src="/images/loading.svg" alt="loading" />
                </div>
              </>
            )}
            <Swiper
              effect={'coverflow'}
              onAutoplayPause={(e) => {
                e.autoplay.start()
              }}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={windowSize <= 640 ? 1.5 : 2}
              loop={true}
              coverflowEffect={{
                rotate: 0,
                slideShadows: false,
                depth: 594,
              }}
              speed={500}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[EffectCoverflow, Autoplay, Pagination]}
              slideActiveClass="slideActiveClass"
              slideNextClass="slideNextClass"
              slidePrevClass="slidePrevClass"
              slideVisibleClass="slideVisibleClass"
            >
              {data?.data?.map((content, index) => {
                return (
                  <SwiperSlide
                    key={content.attributes.createdAt}
                    virtualIndex={index}
                  >
                    <Link
                      to={content.attributes.link_to}
                      target="__blanck"
                      rel="noreferrer"
                      className="slideLink relative m-auto !block aspect-[2/3] h-full w-fit max-w-[calc(100vw_-_1.25rem)] sm:m-[unset] sm:aspect-[16/9] sm:h-auto sm:w-auto"
                    >
                      <img
                        src={
                          windowSize <= 640
                            ? content.attributes.preview_vertical_image?.data?.attributes?.formats
                                ?.large?.url ||
                              content.attributes.preview_vertical_image?.data?.attributes?.formats
                                ?.medium?.url ||
                              content.attributes.preview_vertical_image?.data?.attributes?.url
                            : content.attributes.preview_horizontal_image?.data?.attributes?.formats
                                ?.large?.url ||
                              content.attributes.preview_horizontal_image?.data?.attributes?.formats
                                ?.medium?.url ||
                              content.attributes.preview_horizontal_image?.data?.attributes?.url
                        }
                        className="h-full w-full rounded-[2rem] border-2 border-black object-cover"
                        alt="Slider"
                      />
                    </Link>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  )
}

export default Hero
