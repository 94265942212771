import React from 'react'

const Share = () => {
  return (
    <>
      <div className="flex gap-4">
        <button
          onClick={() =>
            window.open(
              `https://www.facebook.com/sharer.php?u${window.location}`,
              'sharer',
              'toolbar=0,status=0,width=620,height=390'
            )
          }
          className="relative h-12 w-full max-w-[3rem] rounded-full px-4 py-2 text-xl text-white transition-[all] hover:scale-110"
        >
          <img
            src="/images/facebook.svg"
            alt="facebook"
            className="absolute left-2/4 top-2/4 h-8 w-8 -translate-x-2/4 -translate-y-2/4"
          />
        </button>
        <button
          onClick={() =>
            window.open(
              `https://www.linkedin.com/sharing/share-offsite/?url=${window.location}`,
              'sharer',
              'toolbar=0,status=0,width=620,height=390'
            )
          }
          className="relative h-12 w-full max-w-[3rem] rounded-full px-4 py-2 text-xl text-white transition-[all] hover:scale-110"
        >
          <img
            src="/images/linkedin.svg"
            alt="linkedin"
            className="absolute left-2/4 top-2/4 h-8 w-8 -translate-x-2/4 -translate-y-2/4"
          />
        </button>
        <button
          onClick={() =>
            window.open(
              `https://telegram.me/share/url?url=${window.location}`,
              'sharer',
              'toolbar=0,status=0,width=620,height=390'
            )
          }
          className="relative h-12 w-full max-w-[3rem] rounded-full px-4 py-2 text-xl text-white transition-[all] hover:scale-110"
        >
          <img
            src="/images/telegram.svg"
            alt="telegram"
            className="absolute left-2/4 top-2/4 h-8 w-8 -translate-x-2/4 -translate-y-2/4"
          />
        </button>
      </div>
    </>
  )
}

export default Share
