import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Event from '../components/Event'
import EventsList from '../components/Event/EventsList'
import queryString from 'query-string'

const Events = () => {
  const { slug } = useParams<{ slug: string }>()
  const location = useLocation()
  const parseQuery = queryString.parse(location.search)

  if (slug !== undefined && typeof slug === 'string') {
    return (
      <>
        <Event content={{ id: slug }} />
      </>
    )
  }

  return <EventsList {...parseQuery} />
}

export default Events
