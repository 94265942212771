import React from 'react'
import { useResize } from '../libs/hooks/use-resize'
import { useScroll } from '../libs/hooks/use-scroll'

// const logos = [
//   { src: '/images/logos/laravel-logo-white.svg' },
//   { src: '/images/logos/savvycal-logo-white.svg' },
//   { src: '/images/logos/statamic-logo-white.svg' },
//   { src: '/images/logos/transistor-logo-white.svg' },
//   { src: '/images/logos/tuple-logo-white.svg' },
//   { src: '/images/logos/savvycal-logo-white.svg' },
//   { src: '/images/logos/savvycal-logo-white.svg' },
//   { src: '/images/logos/statamic-logo-white.svg' },
//   { src: '/images/logos/transistor-logo-white.svg' },
//   { src: '/images/logos/tuple-logo-white.svg' },
//   { src: '/images/logos/savvycal-logo-white.svg' },
// ]

const parallax1 = [
  { scr: '/images/parallax/1.jpg' },
  { scr: '/images/parallax/2.jpg' },
  { scr: '/images/parallax/3.jpg' },
  { scr: '/images/parallax/4.jpg' },
  { scr: '/images/parallax/5.jpg' },
  { scr: '/images/parallax/6.jpg' },
  { scr: '/images/parallax/7.jpg' },
  { scr: '/images/parallax/8.jpg' },
  { scr: '/images/parallax/9.jpg' },
]

const parallax2 = [
  { scr: '/images/parallax/9.jpg' },
  { scr: '/images/parallax/8.jpg' },
  { scr: '/images/parallax/7.jpg' },
  { scr: '/images/parallax/6.jpg' },
  { scr: '/images/parallax/5.jpg' },
  { scr: '/images/parallax/4.jpg' },
  { scr: '/images/parallax/3.jpg' },
  { scr: '/images/parallax/2.jpg' },
  { scr: '/images/parallax/1.jpg' },
]

const AboutUsCard = () => {
  const scroll = useScroll()
  const resize = useResize()

  return (
    <>
      <div className="relative mb-8 flex justify-center p-2.5 md:mt-16">
        <div className="container">
          <div className="grid font-[AvenirLT] lg:grid-cols-12">
            <div className="mb-4 lg:col-span-5 lg:mb-0 xl:col-span-4">
              <span className="text-3xl font-light uppercase !leading-[1.2] lg:text-5xl">
                UNIQUE MEDIA RESOURCE
              </span>
            </div>
            <div className="lg:col-span-7 xl:col-span-8">
              <p className="xl:text-xl">
                Specializes in content creation, capturing the vibrant essence of city life's
                events. We deliver top-notch photo and video reports, showcasing the highest quality
                storytelling.
              </p>
            </div>
          </div>
          <div className="my-8 flex overflow-hidden">
            <div
              className="flex gap-5"
              style={{
                transform: `translateX(-${
                  (resize >= 768 ? 12.5 : resize >= 640 ? 17 : 25) + scroll / 20
                }%)`,
              }}
            >
              {parallax1.map((prllx, index) => {
                return (
                  <div
                    key={index}
                    className="aspect-[2/3] min-w-[50%] overflow-hidden rounded-2xl sm:min-w-[34%] md:min-w-[25%]"
                  >
                    <img src={prllx.scr} alt="Parallax" className="h-full w-full object-cover" />
                  </div>
                )
              })}
            </div>
          </div>
          {/* <div className="my-8">
            <span className="xl:text-xl">our professionals worked on events of:</span>
            <div className="flex gap-8 overflow-hidden">
              <div className="flex animate-[logoClouds_25s_linear_infinite] gap-8">
                {logos.map((logo, index) => {
                  return (
                    <div
                      key={index}
                      className="flex min-w-[33vw] items-center justify-center sm:min-w-[25vw]"
                    >
                      <img src={logo.src} className="w-full object-cover" alt="Logo" />
                    </div>
                  )
                })}
              </div>
              <div className="flex animate-[logoClouds_25s_linear_infinite] gap-8">
                {logos.map((logo, index) => {
                  return (
                    <div
                      key={index}
                      className="flex min-w-[33vw] items-center justify-center sm:min-w-[25vw]"
                    >
                      <img src={logo.src} className="w-full object-cover" alt="Logo" />
                    </div>
                  )
                })}
              </div>
            </div>
          </div> */}
          <div className="grid font-[AvenirLT] lg:grid-cols-12">
            <div className="mb-4 lg:col-span-5 lg:mb-0">
              <span className="text-3xl font-light uppercase !leading-[1.2] lg:text-5xl">
                A TEAM OF PROFESSIONALS
              </span>
            </div>
            <div className="lg:col-span-7">
              <p className="xl:text-xl">
                Our crew is made up of seasoned photographers and videographers who join forces to
                nail the perfect shot. Each of our squad members rocks in their own unique way,
                whether it's capturing concerts, conferences, theater, portraits, or owning the
                nightlife scene.
              </p>
            </div>
          </div>
          <div className="my-8 flex overflow-hidden">
            <div
              className="flex gap-5"
              style={{
                transform: `translateX(${
                  (resize >= 768 ? -130 + 12.5 : resize >= 640 ? -145.5 + 17 : -160 + 25) +
                  scroll / 20
                }%)`,
              }}
            >
              {parallax2.map((prllx, index) => {
                return (
                  <div
                    key={index}
                    className="aspect-[2/3] min-w-[50%] overflow-hidden rounded-2xl sm:min-w-[34%] md:min-w-[25%]"
                  >
                    <img src={prllx.scr} alt="Parallax" className="h-full w-full object-cover" />
                  </div>
                )
              })}
            </div>
          </div>

          <div className="mx-auto mt-16 max-w-[365px] text-center lg:mt-32 lg:max-w-[585px] xl:mt-48">
            <span className="text-3xl font-light uppercase !leading-[1.2] lg:text-5xl">
              Need a photographer for your event?
            </span>
            <a
              href="https://ddeniss.t.me"
              target="__blank"
              rel="noreferrer"
              className="mt-6 flex w-full items-end justify-center rounded-full border-2 border-cyan-500 bg-transparent bg-gradient-to-r from-transparent to-cyan-500 px-8 py-4 text-2xl uppercase text-white opacity-100 transition-[all] hover:bg-cyan-500 xl:mt-10 xl:px-12 xl:py-6"
            >
              Yes I Do
            </a>
          </div>
          <div className="mx-auto mt-16 w-fit">
            <span className="text-white/40">CLEO in social media</span>
            <div className="mt-2 flex justify-center gap-8">
              <a
                href="https://instagram.com/cleo_cyprus?igshid=MzRlODBiNWFlZA=="
                target="_blank"
                rel="noreferrer"
              >
                <img src="/images/insta.png" alt="icon" className="h-8 w-8" />
              </a>
              <a href="https://www.facebook.com/cleocyprus/" target="_blank" rel="noreferrer">
                <img src="/images/facebook.svg" alt="icon" className="h-8 w-8" />
              </a>
            </div>
          </div>
          <div className="mt-4 flex w-full justify-center gap-4">
            <a
              href="https://www.iubenda.com/privacy-policy/93463135/cookie-policy"
              title="Cookie Policy"
              className="text-white/40 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              Cookie Policy
            </a>
            <a
              href="https://www.iubenda.com/privacy-policy/93463135"
              className="text-white/40 hover:underline"
              title="Privacy Policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </div>
          <div className="mt-4 flex w-full justify-center uppercase text-white/40 hover:underline">
            <a href="https://rc.agency/" target="_blank" rel="noreferrer">
              Powered by Rounded Corners
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUsCard
