import React from 'react'
import Caption from '../components/Caption'
import Hero from '../components/Hero'
import ReportCard from '../components/Reports/ReportCard'
import Skeleton from '../components/Skeleton'
import LazyLoad from 'react-lazyload'
import { useGetReportsPreviewsQuery } from '../store/strapi/strapi.api'
import { EPreviewImageSize } from '../modules'

const General = () => {
  const { data, isLoading } = useGetReportsPreviewsQuery('')

  return (
    <>
      <Hero />
      <Caption caption="PHOTO/VIDEO" />
      <div className="relative mb-8 mt-8 flex justify-center p-2.5 md:mt-16">
        <div className="container">
          <div className="grid grid-cols-1 gap-5 font-sans sm:grid-cols-2 lg:grid-cols-3">
            {isLoading && (
              <>
                <div className="flex w-full justify-center sm:col-span-2 lg:col-span-3">
                  <img src="/images/loading.svg" alt="loading" />
                </div>
              </>
            )}
            {data?.data?.map((report, index) => {
              return (
                <LazyLoad
                  key={report.attributes.caption + index}
                  offset={200}
                  placeholder={<Skeleton />}
                  className={
                    report.attributes.preview_image_size === EPreviewImageSize.SQUARE
                      ? 'lg:col-span-2 lg:row-span-2'
                      : report.attributes.preview_image_size === EPreviewImageSize.VERTICAL
                      ? 'row-span-2 [&>a]:aspect-[2/3] [&>a]:h-full'
                      : ''
                  }
                >
                  <ReportCard
                    content={{
                      id: `${report.id}`,
                      place: report.attributes.place?.data?.attributes?.caption,
                      name: report.attributes.caption,
                      preview:
                      report.attributes.preview_image.data.attributes.formats?.large?.url || report.attributes.preview_image.data.attributes.formats?.medium?.url ||
                        report.attributes.preview_image.data.attributes.url,
                      date: report.attributes.date,
                    }}
                  />
                </LazyLoad>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default General
