import React from 'react'
import AboutUsCard from '../components/AboutUsCard'

const AboutUs = () => {
  return (
    <>
      <AboutUsCard />
    </>
  )
}

export default AboutUs
