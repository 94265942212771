import React, { useEffect } from 'react'
import { clsx } from 'clsx'
import Share from '../Buttons/Share'
import { useGetPlaceQuery, useLazyGetReportsPreviewsQuery } from '../../store/strapi/strapi.api'
import ReactMarkdown from 'react-markdown'
import Caption from '../Caption'
import LazyLoad from 'react-lazyload'
import Skeleton from '../Skeleton'
import ReportCard from '../Reports/ReportCard'
import { createSearchParams } from 'react-router-dom'
import Loadmore from '../Buttons/Loadmore'

export interface IPlace {
  id: string
}

const Place = ({ content }: { content: IPlace }) => {
  const { data, isLoading, isSuccess } = useGetPlaceQuery(content.id)
  const [getReportsPreviews, { data: dataReports }] = useLazyGetReportsPreviewsQuery()

  useEffect(() => {
    if (isSuccess && data?.data?.length !== 0) {
      getReportsPreviews(`&filters[place][caption][$eqi][0]=${data?.data[0]?.attributes.caption}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  return (
    <>
      {isLoading ? (
        <>
          <div className="flex h-full w-full justify-center">
            <img src="/images/loading.svg" alt="loading" />
          </div>
        </>
      ) : (
        <>
          <section id="Arcticle" className="mt-8 flex justify-center p-2.5 lg:mt-16">
            <div className="container">
              {data?.data.length !== 0 ? (
                <>
                  <div className="grid gap-8 lg:grid-cols-12">
                    <div className="order-2 col-span-1 grid content-start gap-8 lg:order-1 lg:col-span-5">
                      <h1 className="text-4xl font-bold uppercase lg:text-6xl">
                        {data?.data[0].attributes.caption}
                      </h1>
                      <div className="flex grid-cols-2 flex-wrap justify-between gap-4 text-lg">
                        {data?.data[0].attributes.check && (
                          <div>
                            <span className="uppercase text-white/40">Check: </span>
                            <span className="font-sans text-white">
                              {data?.data[0].attributes.check}
                            </span>
                          </div>
                        )}
                        <div className="w-full">
                          <span className="uppercase tracking-wide text-white/40">
                            Description:{' '}
                          </span>
                          <ReactMarkdown
                            className={clsx(
                              'tracking-wid relative whitespace-pre-line font-sans text-base'
                            )}
                          >
                            {data?.data[0].attributes.description!}
                          </ReactMarkdown>
                        </div>
                        <div className="w-full">
                          <span className="mt-8 uppercase text-white/40">Share: </span>
                          <Share />
                        </div>
                      </div>
                    </div>
                    <div className="order-1 col-span-1 w-full lg:order-2 lg:col-span-7">
                      <div className="relative aspect-[3/2] w-full overflow-hidden rounded-3xl">
                        <img
                          className="absolute inset-0 z-0 h-full w-full object-cover"
                          src={
                            data?.data[0].attributes.preview_image.data.attributes.formats.medium
                              .url
                          }
                          alt="Banner"
                        />
                        <div className="absolute inset-0 p-4 lg:p-6">
                          <span className="w-fit rounded-full bg-black/60 px-2 py-1 text-base uppercase backdrop-blur-[5px]">
                            {data?.data[0].attributes.type_of_place?.data?.attributes?.type_name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 w-full">
                    <span className="text-lg uppercase text-white/40">Contacts: </span>
                    <div className="mt-2 grid grid-cols-1 gap-5 lg:grid-cols-12">
                      <div
                        className="aspect-[3/2] overflow-hidden rounded-2xl lg:col-span-7 [&>iframe]:h-full [&>iframe]:w-full"
                        dangerouslySetInnerHTML={{ __html: data?.data[0].attributes.maps_frame! }}
                      />
                      <div className="flex flex-col gap-4 overflow-hidden rounded-2xl lg:col-span-5">
                        <div className="flex flex-col gap-2">
                          {data?.data[0].attributes.phone && (
                            <div className="flex items-start gap-4 uppercase">
                              <img src="/images/call.png" alt="icon" className="h-6 w-6" />
                              <span>{data?.data[0].attributes.phone}</span>
                            </div>
                          )}
                          {data?.data[0].attributes.web_site && (
                            <div className="flex items-start gap-4 uppercase underline underline-offset-1">
                              <img src="/images/website.png" alt="icon" className="h-6 w-6" />
                              <a
                                href={`${data?.data[0].attributes.web_site}`}
                                target={'__blank'}
                                rel={'noreferrer'}
                              >
                                {data?.data[0].attributes.caption}
                              </a>
                            </div>
                          )}
                          {data?.data[0].attributes.email && (
                            <div className="flex items-start gap-4 uppercase underline underline-offset-1">
                              <img src="/images/mail.png" alt="icon" className="h-6 w-6" />
                              <a href={`mailto:${data?.data[0].attributes.email}`}>
                                {data?.data[0].attributes.email}
                              </a>
                            </div>
                          )}
                          {data?.data[0].attributes.address && (
                            <div className="flex items-start gap-4 uppercase">
                              <img src="/images/address.png" alt="icon" className="h-6 w-6" />
                              <span>{data?.data[0].attributes.address}</span>
                            </div>
                          )}
                          {(data?.data[0].attributes.instagram_name ||
                            data?.data[0].attributes.facebook_name) && (
                            <div className="mt-4 flex flex-col gap-2">
                              {data?.data[0].attributes.instagram_name && (
                                <div className="flex items-start gap-4 uppercase underline underline-offset-1">
                                  <img src="/images/insta.png" alt="icon" className="h-6 w-6" />
                                  <a
                                    href={`https://www.instagram.com/${data?.data[0].attributes.instagram_name}`}
                                    target={'__blank'}
                                    rel={'noreferrer'}
                                  >
                                    @{data?.data[0].attributes.instagram_name}
                                  </a>
                                </div>
                              )}
                              {data?.data[0].attributes.facebook_name && (
                                <div className="flex items-start gap-4 uppercase underline underline-offset-1">
                                  <img src="/images/fb.png" alt="icon" className="h-6 w-6" />
                                  <a
                                    href={`https://www.facebook.com/${data?.data[0].attributes.facebook_name}`}
                                    target={'__blank'}
                                    rel={'noreferrer'}
                                  >
                                    /{data?.data[0].attributes.facebook_name}
                                  </a>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <span className="uppercase">No Items Found</span>
              )}
            </div>
          </section>
        </>
      )}
      {isSuccess && dataReports && dataReports?.data?.length !== 0 && (
        <>
          <Caption caption="More photos from this place" size="lg" />
          <div className="relative mb-8 mt-8 flex justify-center p-2.5 md:mt-16">
            <div className="container">
              <div className="grid grid-cols-1 gap-5 font-sans sm:grid-cols-2 lg:grid-cols-3">
                {dataReports?.data?.slice(0, 2).map((report, index) => {
                  return (
                    <LazyLoad
                      key={report?.attributes.caption + index}
                      offset={200}
                      placeholder={<Skeleton />}
                    >
                      <ReportCard
                        content={{
                          id: `${report?.id}`,
                          place: report?.attributes.place?.data?.attributes?.caption,
                          name: report?.attributes.caption,
                          preview:
                            report?.attributes?.preview_image?.data?.attributes?.formats?.medium
                              ?.url || report?.attributes?.preview_image?.data?.attributes?.url,
                          date: report?.attributes.date,
                        }}
                      />
                    </LazyLoad>
                  )
                })}
                {data?.data.length !== 0 && (
                  <Loadmore
                    pathname="/reports"
                    search={`?${createSearchParams({
                      place: data?.data[0].attributes.caption!,
                    })}`}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Place
