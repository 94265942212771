import { useEffect, useState } from 'react'

export const useScroll = () => {
  const [windowScroll, setWindowScroll] = useState(window.scrollY)

  useEffect(() => {
    const handleWindowScroll = () => {
      setWindowScroll(window.scrollY)
    }

    window.addEventListener('scroll', handleWindowScroll)

    return () => {
      window.removeEventListener('scroll', handleWindowScroll)
    }
  })

  return windowScroll
}
