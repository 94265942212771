import React from 'react'

const YesIDo = () => {
  return (
    <a
      href="https://ddeniss.t.me"
      target="__blank"
      rel="noreferrer"
      className="mt-6 flex w-full max-w-[320px] items-end justify-center rounded-full border-2 border-cyan-500 bg-transparent bg-gradient-to-r from-transparent to-cyan-500 px-8 py-4 text-2xl uppercase text-white opacity-100 transition-[all] hover:bg-cyan-500 xl:mt-10 xl:max-w-[640px] xl:px-12 xl:py-6"
    >
      Yes I Do
    </a>
  )
}

export default YesIDo
