import React, { useState, useEffect } from 'react'
import LazyLoad from 'react-lazyload'
import { useLazyGetPlacesPreviewsQuery } from '../../store/strapi/strapi.api'
import Caption from '../Caption'
import Hero from '../Hero'
import Skeleton from '../Skeleton'
import PlaceCard from './PlaceCard'
import PlaceFilter from './PlaceFilter'

export interface IPlaceList {
  caption?: string
  type?: string
}

const PlacesList = ({ caption, type }: IPlaceList) => {
  const [getPlacesPreviews, { data, isSuccess, isFetching }] = useLazyGetPlacesPreviewsQuery()
  const [isActiveFilter, setIsActiveFilter] = useState(false)

  useEffect(() => {
    getPlacesPreviews(
      [
        `${caption ? `&filters[caption][$containsi][0]=${caption}` : ''}`,
        `${type ? `&filters[type_of_place][type_name][$eqi][1]=${type}` : ''}`,
      ].join('')
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caption, type])

  return (
    <>
      <Hero />
      <Caption
        caption="THE PLACES WE SUGGEST"
        onClick={() => {
          setIsActiveFilter((e) => !e)
        }}
      />
      <div className="relative mb-8 mt-8 flex justify-center p-2.5 md:mt-10">
        <div className="container">
          <PlaceFilter isActive={isActiveFilter} captionFilter={caption} typeFilter={type} />
          <div className="grid grid-cols-1 gap-5 font-sans sm:grid-cols-2 lg:grid-cols-3">
            {isFetching && (
              <>
                <div className="flex w-full justify-center sm:col-span-2 lg:col-span-3">
                  <img src="/images/loading.svg" alt="loading" />
                </div>
              </>
            )}
            {isSuccess &&
              (data?.data.length !== 0 ? (
                data?.data?.map((place, index) => {
                  return (
                    <LazyLoad
                      key={place.attributes.caption + index}
                      offset={200}
                      placeholder={<Skeleton />}
                    >
                      <PlaceCard
                        content={{
                          id: `${place.id}`,
                          type: place.attributes.type_of_place?.data?.attributes?.type_name,
                          name: place.attributes.caption,
                          preview:
                            place.attributes.preview_image.data.attributes.formats?.medium?.url ||
                            place.attributes.preview_image.data.attributes.url,
                          price: place.attributes.check,
                        }}
                      />
                    </LazyLoad>
                  )
                })
              ) : (
                <span className="uppercase">No Items Found</span>
              ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default PlacesList
