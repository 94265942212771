import clsx from 'clsx'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useGetOrganizerQuery } from '../../store/strapi/strapi.api'
import Share from '../Buttons/Share'

export interface IOrg {
  id: string
}

const Organizer = ({ content }: { content: IOrg }) => {
  const { data, isLoading } = useGetOrganizerQuery(content.id)

  return (
    <>
      {isLoading ? (
        <>
          <div className="flex h-full w-full justify-center">
            <img src="/images/loading.svg" alt="loading" />
          </div>
        </>
      ) : (
        <>
          <section id="Arcticle" className="mt-8 flex justify-center p-2.5 lg:mt-16">
            <div className="container">
              {data?.data.length !== 0 ? (
                <div className="grid gap-8 lg:grid-cols-12">
                  <div className="order-2 col-span-1 grid content-start gap-8 lg:order-1 lg:col-span-5">
                    <h1 className="text-4xl font-bold uppercase lg:text-6xl">
                      {data?.data[0].attributes.name}
                    </h1>
                    <div className="flex grid-cols-2 flex-wrap justify-between gap-4 text-lg">
                      <div className="w-full">
                        <span className="uppercase tracking-wide text-white/40">Description: </span>
                        <ReactMarkdown
                          className={clsx(
                            'tracking-wid relative whitespace-pre-line font-sans text-base'
                          )}
                        >
                          {data?.data[0].attributes.description!}
                        </ReactMarkdown>
                      </div>
                      <div className="w-full">
                        <span className="mt-8 uppercase text-white/40">Share: </span>
                        <Share />
                      </div>
                      <div className="w-full">
                        <span className="mt-8 uppercase text-white/40">Contacts: </span>
                        {data?.data[0].attributes.phone && (
                          <div className="flex items-start gap-4 uppercase">
                            <img src="/images/call.png" alt="icon" className="h-6 w-6" />
                            <span>{data?.data[0].attributes.phone}</span>
                          </div>
                        )}
                        {data?.data[0].attributes.web_site && (
                          <div className="flex items-start gap-4 uppercase underline underline-offset-1">
                            <img src="/images/website.png" alt="icon" className="h-6 w-6" />
                            <a
                              href={`${data?.data[0].attributes.web_site}`}
                              target={'__blank'}
                              rel={'noreferrer'}
                            >
                              {data?.data[0].attributes.name}
                            </a>
                          </div>
                        )}
                        {data?.data[0].attributes.email && (
                          <div className="flex items-start gap-4 uppercase underline underline-offset-1">
                            <img src="/images/mail.png" alt="icon" className="h-6 w-6" />
                            <a href={`mailto:${data?.data[0].attributes.email}`}>
                              {data?.data[0].attributes.email}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="order-1 col-span-1 w-full lg:order-2 lg:col-span-7">
                    <div className="relative aspect-[3/2] w-full overflow-hidden rounded-3xl">
                      <img
                        className="absolute inset-0 z-0 h-full w-full object-cover"
                        src={
                          data?.data[0].attributes.preview_image.data.attributes.formats.medium.url
                        }
                        alt="Banner"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <span className="uppercase">No Items Found</span>
              )}
            </div>
          </section>
        </>
      )}
    </>
  )
}

export default Organizer
