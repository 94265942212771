import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import Organizer from '../components/Organizer'

const Org = () => {
  const { slug } = useParams<{ slug: string }>()

  if (slug !== undefined && typeof slug === 'string') {
    return (
      <>
        <Organizer content={{ id: slug }} />
      </>
    )
  }

  return <Navigate to={'/'} />
}

export default Org
