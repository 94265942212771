import React from 'react'
import YesIDo from './Buttons/YesIDo'

const BooknowCard = () => {
  return (
    <>
      <section id="Booknow" className={'flex min-w-full justify-center p-2.5 md:mt-16 lg:mt-8'}>
        <div className="container">
          <div className="grid h-[calc(100dvh_-_70px_-_6rem)] items-center gap-4 md:grid-cols-3 md:py-8 lg:h-[calc(100vh_-_70px_-_2rem_-_1.25rem)] xl:gap-8">
            <div className="md:order-0 order-1 mx-auto flex flex-col justify-center md:col-span-2 md:mx-0">
              <div className="flex flex-col text-3xl font-bold uppercase leading-[1.1875] tracking-wide md:text-5xl xl:text-7xl">
                <span>Do you need</span>
                <span>
                  A{' '}
                  <div className="inline-block">
                    <span className="absolute animate-[animationWords_4s_linear_infinite_0s] overflow-hidden text-cleo_cyan opacity-0">
                      photo
                    </span>
                    <span className="delay-2 absolute ml-[15px] animate-[animationWords_4s_linear_infinite_0s] overflow-hidden text-cleo_pink opacity-0 md:ml-[24px] xl:ml-[37px]">
                      video
                    </span>
                    <span className="pointer-events-none select-none text-transparent">photo</span>
                  </div>
                  grapher
                </span>
                <span>For your event?</span>
              </div>
              <YesIDo />
            </div>
            <div className="order-0 mx-auto hidden aspect-[2/3] h-full overflow-hidden rounded-[6rem] md:order-1 md:mx-0 md:block md:h-auto md:rounded-[12rem]">
              <img src="/images/booknow.webp" alt="Booknow men" className="h-full object-cover" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BooknowCard
