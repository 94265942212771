import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IBanner } from '../../modules/banner'
import { ICollectionRoot, ISingleRoot } from '../../modules'
import { IPutReport, IReport, IReportPreview } from '../../modules/report'
import { IEvent, IEventPreview, ITypeOfEvent } from '../../modules/event'
import { IPlace, ITypeOfPlace } from '../../modules/place'
import { IPhotographer } from '../../modules/photagrapher'
import { IOrganizer } from '../../modules/organizer'
import { IAds } from '../../modules/ads'

const STRAPI_TOKEN = process.env.REACT_APP_STRAPI_TOKEN
const STRAPI_HOST = process.env.REACT_APP_STRAPI_HOST

export const strapiApi = createApi({
  reducerPath: 'strapi/api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${STRAPI_HOST}/api/`,
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${STRAPI_TOKEN}`)
    },
  }),
  endpoints: (build) => ({
    getBanners: build.query<ICollectionRoot<IBanner>, any>({
      query: () => ({
        url: `banners?populate=*&sort[0]=date:asc`,
      }),
    }),
    getReportsPreviews: build.query<ICollectionRoot<IReportPreview>, string>({
      query: (params) => ({
        url: `reports?sort[0]=date:desc&populate=place&populate=preview_image&populate=photographer${params}`,
      }),
    }),
    getReports: build.query<ICollectionRoot<IReport>, any>({
      query: () => ({
        url: `reports?populate=*`,
      }),
    }),
    getReport: build.query<ICollectionRoot<IReport>, string>({
      query: (id) => ({
        url: `reports?populate=*&filters[id][$in][0]=${id}`,
      }),
    }),
    getReporT: build.query<ICollectionRoot<IReport>, string>({
      query: (id) => ({
        url: `reports?filters[id][$in][0]=${id}`,
      }),
    }),
    getEventsPreviews: build.query<ICollectionRoot<IEventPreview>, string>({
      query: (params) => ({
        url: `events?sort[0]=date_start:asc&populate=preview_image&populate=type_of_event${params}`,
      }),
    }),
    getEvent: build.query<ICollectionRoot<IEvent>, string>({
      query: (id) => ({
        url: `events?populate=preview_image&populate=place.preview_image&populate=type_of_event&filters[id][$in][0]=${id}`,
      }),
    }),
    getPlacesPreviews: build.query<ICollectionRoot<IPlace>, string>({
      query: (params) => ({
        url: `places?populate=*${params}`,
      }),
    }),
    getPlace: build.query<ICollectionRoot<IPlace>, string>({
      query: (id) => ({
        url: `places?populate=*&filters[id][$in][0]=${id}`,
      }),
    }),
    getTypeOfEvent: build.query<ICollectionRoot<ITypeOfEvent>, any>({
      query: () => ({
        url: `type-of-events`,
      }),
    }),
    getTypeOfPlace: build.query<ICollectionRoot<ITypeOfPlace>, any>({
      query: () => ({
        url: `type-of-places`,
      }),
    }),
    getPhotographers: build.query<ICollectionRoot<IPhotographer>, any>({
      query: () => ({
        url: `photographers?populate=*`,
      }),
    }),
    getPhotographer: build.query<ICollectionRoot<IPhotographer>, string>({
      query: (id) => ({
        url: `photographers?filters[id][$in][0]=${id}&populate=*`,
      }),
    }),
    getOrganizer: build.query<ICollectionRoot<IOrganizer>, string>({
      query: (id) => ({
        url: `organizers?filters[id][$in][0]=${id}&populate=*`,
      }),
    }),
    getAds: build.query<ISingleRoot<IAds>, any>({
      query: () => ({
        url: `ads-banner?populate=*`,
      }),
    }),
    putView: build.mutation<ISingleRoot<IReport>, IPutReport>({
      query: ({ id, count_views }: { id: number; count_views: number }) => ({
        url: `reports/${id}`,
        method: 'PUT',
        body: { data: { count_views: count_views } },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
})

export const {
  useGetBannersQuery,
  useGetReporTQuery,
  useGetReportsPreviewsQuery,
  useLazyGetReportsPreviewsQuery,
  useGetReportsQuery,
  useGetReportQuery,
  useGetEventsPreviewsQuery,
  useLazyGetEventsPreviewsQuery,
  useGetEventQuery,
  useGetPlacesPreviewsQuery,
  useLazyGetPlacesPreviewsQuery,
  useGetPlaceQuery,
  usePutViewMutation,
  useGetTypeOfEventQuery,
  useGetTypeOfPlaceQuery,
  useGetPhotographersQuery,
  useGetOrganizerQuery,
  useGetPhotographerQuery,
  useLazyGetAdsQuery,
} = strapiApi
